.loginBox {
    border-style: solid;
    border-width: 1px;
}

.btnLogin {
    display: block;
    width: 100%;
    height: 1vh;
    background-color: #000;
    padding: 14px 28px;
    font-size: 16px;
    text-align: center;
    border-radius: 10%;
}

.container {
    position: relative;
    height: 50vh; /* Du kannst die Höhe nach Bedarf anpassen */
  }
  
.imgLogin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
}
  